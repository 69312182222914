require('jquery-easing');
import '../styles/index.scss';

import $ from 'jquery';
import jQuery from 'jquery';

window.$ = jQuery;
import 'jplayer';
import 'bootstrap';
import 'lightbox2';

const basicLightbox = require('basiclightbox');
const WOW = require('wowjs').WOW;
var i;
var JSON;
$.getJSON("/src/section-list.json", function (data) {
    JSON = data;
});

function createMenu() {
    var menu = '';
    var menu_mobile = '';
    var button_text = '';
    for (i = 0; i <= JSON.length; i++) {
        if (i === 0) {
            button_text = '<div class = "button-text">Главная</div>';
            menu = menu + '<li class = "button page-scroll active">' + '<a href = "' + '#main" data-scroll="main">' + button_text + '</a>' + '</li>';
            menu_mobile = menu_mobile + '<li class = "button page-scroll active">' + '<a href = "' + '#main" data-scroll="main">' + button_text + '</a>' + '</li>';
        } else {
            button_text = '<div class = "button-text">' + String(JSON[i - 1].sectionname) + '</div>';
            menu = menu + '<li class = "button page-scroll">' + '<a href = "' + '#' + String(JSON[i - 1].sectionlink) + '" data-scroll="' + String(JSON[i - 1].sectionlink) + '">' + button_text + '</a>' + '</li>';
            menu_mobile = menu_mobile + '<li class = "button page-scroll">' + '<a href = "' + '#' + String(JSON[i - 1].sectionlink) + '" data-scroll="' + String(JSON[i - 1].sectionlink) + '">' + button_text + '</a>' + '</li>';
        }
    }
    menu = '<ul class = "menu">' + menu + '</ul>';
    menu_mobile = '<div class = "menu-button"><div class = "button-text">Меню</div></div><ul class = "menu-mobile hidden">' + menu_mobile + '</ul>';
    $('.menu-wrapper').append(menu, menu_mobile);
}

function createMainPage() {
    var page = '';
    var main_page = '';
    for (i = 0; i < JSON.length; i++) {
        if (i % 2 == 0) {
            page = '<div class = "page-wrapper"  id ="' + String(JSON[i].sectionlink) + '">' + '<div class = "section-container"><div class = "section-name">' + String(JSON[i].sectionname) + '</div>' + '<div class = "section-content">' + '</div>' + '</div>' + '</div>';
        } else {
            page = '<div class = "page-wrapper even" id ="' + String(JSON[i].sectionlink) + '">' + '<div class = "section-container"><div class = "section-name">' + String(JSON[i].sectionname) + '</div>' + '<div class = "section-content">' + '</div>' + '</div>' + '</div>';
        }
        main_page += page;
    }
    $('.main-page').append(main_page);
    //createAlbums();
    createPhoto();
    createPlayer();
    createVideo();
    createAbout();
    createContacts();
}


function createPlayer() {
    var playlist = [];
    for (i = 0; i < JSON[0].listofitems.length; i++) {
        for (var j = 0; j < JSON[0].listofitems[i].albumcontent.length; j++) {
            playlist.push({
                title: String(JSON[0].listofitems[i].albumcontent[j].name),
                mp3: "../src/mp3/" + String(JSON[0].listofitems[i].albumname) + "/" + String(JSON[0].listofitems[i].albumcontent[j].link)
            });
        }
    }

    var lottie = '<div class="lottie-container"><lottie-player class="lottie" src="/src/img/sound.json"  background="transparent"  speed="1"  style="width: 40px; height: 40px;"  loop autoplay></lottie-player></div>';
    var player = '' +
        '<div id="jquery_jplayer_1" class="jp-jplayer"></div>\n' +
        '        <div id="jp_container_1" class="jp-audio" role="application" aria-label="media player">\n' +
        '            <div class="jp-type-playlist">\n' +
        '                <div class="jp-gui jp-interface">\n' +
        '                    <div class="jp-controls">\n' +
        '                        <button class="jp-play" role="button" tabindex="0">play</button>\n' +
        '                        <button class="jp-stop" role="button" tabindex="0">stop</button>\n' +
        '                    </div>\n' +
        '                    <div class="song-details">\n' +
        '                       <div class="jp-time-holder">\n' +
        '                           <div class="jp-current-time" role="timer" aria-label="time">&nbsp;</div>\n' +
        '                           <div class="jp-title" aria-label="title">&nbsp;</div>\n' +
        '                                  <div class="jp-duration" role="timer" aria-label="duration">&nbsp;</div>\n' +
        '                        </div>\n' +
        '                       <div class="jp-progress">\n' +
        '                         <div class="jp-seek-bar">\n' +
        '                                <div class="jp-play-bar"></div>\n' +
        '                           </div>\n' +
        '                       </div>\n' +
        '                    </div>\n' +
        '                    <div class="jp-volume-controls">\n' +
        '                        <button class="jp-mute" role="button" tabindex="0">mute</button>\n' +
        '                        <div class="jp-volume-bar">\n' +
        '                            <div class="jp-volume-bar-value"></div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="button-container">\n' +
        '                        <div class="download-button"></div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="jp-playlist"\n>' +
        '                    <ul style="display: block;">\n' +
        '                        <li class="jp-playlist-current">\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item jp-playlist-current" tabindex="1">Cro\n' +
        '                                Magnon Man</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Your Face</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Cyber Sonnet</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Tempered Song</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Hidden</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove"\n' +
        '                                    style="display: none;">×</a><span class="jp-free-media">(<a\n' +
        '                                    class="jp-playlist-item-free"\n' +
        '                                    href="http://www.jplayer.org/audio/mp3/Miaow-03-Lentement.mp3" tabindex="1">mp3</a> | <a\n' +
        '                                    class="jp-playlist-item-free"\n' +
        '                                    href="http://www.jplayer.org/audio/ogg/Miaow-03-Lentement.ogg" tabindex="1">oga</a>)</span><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Lentement</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Lismore</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">The Separation</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Beside Me</a></div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove"\n' +
        '                                    style="display: none;">×</a><span class="jp-free-media">(<a\n' +
        '                                    class="jp-playlist-item-free"\n' +
        '                                    href="http://www.jplayer.org/audio/mp3/Miaow-07-Bubble.mp3"\n' +
        '                                    tabindex="1">mp3</a> | <a class="jp-playlist-item-free"\n' +
        '                                                              href="http://www.jplayer.org/audio/ogg/Miaow-07-Bubble.ogg"\n' +
        '                                                              tabindex="1">oga</a>)</span><a href="javascript:;"\n' +
        '                                                                                             class="jp-playlist-item"\n' +
        '                                                                                             tabindex="1">Bubble</a>\n' +
        '                            </div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a><a\n' +
        '                                    href="javascript:;" class="jp-playlist-item" tabindex="1">Stirring of a Fool</a>\n' +
        '                            </div>\n' +
        '                        </li>\n' +
        '                        <li>\n' +
        '                            <div><a href="javascript:;" class="jp-playlist-item-remove"\n' +
        '                                    style="display: none;">×</a><span class="jp-free-media">(<a\n' +
        '                                    class="jp-playlist-item-free"\n' +
        '                                    href="http://www.jplayer.org/audio/mp3/Miaow-09-Partir.mp3"\n' +
        '                                    tabindex="1">mp3</a> | <a class="jp-playlist-item-free"\n' +
        '                                                              href="http://www.jplayer.org/audio/ogg/Miaow-09-Partir.ogg"\n' +
        '                                                              tabindex="1">oga</a>)</span><a href="javascript:;"\n' +
        '                                                                                             class="jp-playlist-item"\n' +
        '                                                                                             tabindex="1">Partir</a>\n' +
        '                            </div>\n' +
        '                        </li>\n' +
        '                    </ul>\n' +
        '                </div>\n' +
        '                <div class="jp-no-solution">\n' +
        '                    <span>Update Required</span>\n' +
        '                    To play the media you will need to either update your browser to a recent version or update your <a\n' +
        '                        href="http://get.adobe.com/flashplayer/" target="_blank">Flash plugin</a>.\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>';
    $('.main-page #songs .section-content').append(player);


    var myPlaylist = new jPlayerPlaylist({
            jPlayer: "#jquery_jplayer_1",
            cssSelectorAncestor: "#jp_container_1"
        }, playlist,
        {
            playlistOptions: {
                enableRemoveControls: false
            },
            swfPath: "https://cdnjs.cloudflare.com/ajax/libs/jplayer/2.9.2/jplayer",
            supplied: "mp3",
            useStateClassSkin: true,
            autoBlur: false,
            smoothPlayBar: true,
            keyEnabled: true,
            verticalVolume: true,
            volume: 0.1,
            noVolume: false
        });

    var lottie = '<div class="lottie-container"><lottie-player class="lottie" src="/src/img/sound.json"  background="transparent"  speed="1"  style="width: 40px; height: 40px;"  loop autoplay></lottie-player></div>';
    const playlist_item = document.querySelector("div.jp-playlist>ul>li>div");
    $(playlist_item).append(lottie);
}


function createAlbums() {

    var album = '';
    var list_of_items;

    for (i = 0; i < JSON[0].listofitems.length; i++) {
        var album_name = '';
        var album_content = '';
        album_name = '<div class = "album-name">' + String(JSON[0].listofitems[i].albumname) + '</div>';
        for (var j = 0; j < JSON[0].listofitems[i].albumcontent.length; j++) {
            album_content = album_content + '<div class = "album-content-item">' + String(JSON[0].listofitems[i].albumcontent[j].name) + '</div>';
        }
        if (i === 0) {
            album_content = '<div id="jp_container_1" class="jp-audio album-content" role="application" aria-label="media player">' +
                '            <div class="jp-type-playlist">\n' +
                '                <div id="jquery_jplayer_1" class="jp-jplayer"></div>\n' +
                '                <div class="jp-gui">\n' +
                '                    <div class="jp-video-play">\n' +
                '                        <button class="jp-video-play-icon" role="button" tabindex="0">play</button>\n' +
                '                    </div>\n' +
                '                    <div class="jp-interface">\n' +
                '                        <div class="jp-controls-holder">\n' +
                '                            <div class="jp-controls">\n' +
                '                                <button class="jp-play" role="button" tabindex="0"></button>\n' +
                '                            </div>\n' +
                '                        </div>\n' +
                '                        <div class="current-play-container">\n' +
                '                           <div class="title-container">\n' +
                '                               <div class="jp-title" aria-label="title">&nbsp;</div>\n' +
                '                               <div class="jp-current-time" role="timer" aria-label="time">&nbsp;</div>\n' +
                '                           </div>\n' +
                '                           <div class="jp-progress">\n' +
                '                               <div class="jp-seek-bar">\n' +
                '                                   <div class="jp-play-bar"></div>\n' +
                '                               </div>\n' +
                '                           </div>\n' +
                '                        </div>\n' +
                '                        <div class="jp-controls-holder">\n' +
                '                            <div class="jp-volume-controls">\n' +
                '                                <div class="jp-volume-bar">\n' +
                '                                    <div class="jp-volume-bar-value"></div>\n' +
                '                                </div>\n' +
                '                            </div>\n' +
                '                        </div>\n' +
                '                        <a href="" class="download-link" download><button class="jp-download" role="button" tabindex="0"></button></a>\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '                <div class="jp-playlist">\n' +
                '                    <ul>\n' +
                '                        <!-- The method Playlist.displayPlaylist() uses this unordered list -->\n' +
                '                        <li></li>\n' +
                '                    </ul>\n' +
                '                </div>\n' +
                '                <div class="jp-no-solution">\n' +
                '                    <span>Update Required</span>\n' +
                '                    To play the media you will need to either update your browser to a recent version or update your <a\n' +
                '                        href="https://get.adobe.com/flashplayer/" target="_blank">Flash plugin</a>.\n' +
                '                </div>\n' +
                album_content + '</div>';
        } else {
            album_content = '<div class = "album-content">' + album_content + '</div>';
        }
        album = album + '<div class = "album">' + album_name + album_content + '</div>';

    }
    list_of_items = '<div class = "list-of-items">' + album + '</div>';
    $('.main-page #songs .section-content').append(list_of_items);
}


function createPhoto() {
    var photo = '';
    for (i = 0; i < JSON[1].listofitems.length; i++) {
        var image_source = '../src/photo/' + String(JSON[1].listofitems[i].link);
        photo += '<a href = "' + image_source + '" data-lightbox = "photo"><div style ="background-image: url(' + image_source + ')" class = "photo"></div></a>';
    }
    $('.main-page #photo .section-content').append('<div class = "photo-container">' + photo + '</div>');
}

function createVideo() {
    var video_container;
    var video = '';
    for (i = 0; i < JSON[2].listofitems.length; i++) {

        var video_source = 'https://www.youtube.com/embed/' + String(JSON[2].listofitems[i].id);
        //var preview = 'url(src/photo/' + String(data[2].listofitems[i].preview) + ')';
        var preview = 'url(https://i.ytimg.com/vi/' + String(JSON[2].listofitems[i].id) + '/maxresdefault.jpg)';

        video += '<div class="video-preview"><div class="video-overlay"></div><div class="video-overlay-cont"><div class="video-btn-cont"><i class="fa fa-play fa-very-white-button"></i></div></div><div class="video-preview-img" style="background-image: ' + preview + '"></div><img class="video-viewer" src="' + video_source + '?controls=1&color=white&autoplay=1"></div>';
    }
    video_container = '<div class="video-container"><div class="video-block"><div class="video-row wow fadeInRight" data-wow-delay="400ms" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 400ms;">' + video + '</div></div></div>';
    $('.main-page #video .section-content').append(video_container);
}

function createAbout() {
    var text = '<div class = "text">' + JSON[3].listofitems[0].text + '</div>';
    //console.log(JSON[3].listofitems[0].text);
    $('.main-page #about .section-content').append(text);
}

function createContacts() {
    var email = '<div class="email">' + 'email: ' + JSON[4].listofitems[0].email + '</div>';
    $('.main-page #contacts .section-content').append(email);
}

const html = '<div class="lightbox-container"><iframe class="video-iframe" src="" frameborder="0"></iframe></div>';
var videoframe_lightbox = '';
videoframe_lightbox = basicLightbox.create(html);

$(document).ready(function () {
    $('.lb-dataContainer').after($('.lb-outerContainer'));
});

$(window).on('load', function () {
    createMenu();
    createMainPage();

});


var download_flag = 0;

document.addEventListener("DOMContentLoaded", function () {
    const elementToObserve = document.querySelector(".main-page");
    const observer = new MutationObserver(callback);
    observer.observe(elementToObserve, {
        subtree: true,
        attributes: true,
        attributeFilter: ["class"]
    });

    function callback(mutations, observer) {
        $('body div.lottie-container').remove();
        var lottie = '<div class="lottie-container play"><lottie-player class="lottie" src="/src/img/sound.json"  background="transparent"  speed="1" style="/*width: 40px; height: 40px;*/"  loop autoplay></lottie-player></div>' +
            '<div class="lottie-container pause"><lottie-player class="lottie" src="/src/img/sound.json"  background="transparent"  speed="1"  style="width: 40px; height: 40px;"  loop></lottie-player></div>';
        const lottie_playlist = document.querySelector("body div.jp-state-playing div.jp-playlist>ul>.jp-playlist-current>div");

        $(lottie_playlist).append(lottie);
        $('.page-scroll').on('click', 'a', function () {
            var $anchor = $(this);
            $("ul.menu li.active").toggleClass('active');
            $(this).parent().addClass('active');
            $('html, body').stop().animate({
                scrollTop: ($($anchor.attr('href')).offset().top - 50)
            }, 1250, 'easeInOutExpo');
            event.preventDefault();
            $("ul.menu-mobile").toggleClass('hidden');
        });
        $('.menu-button').on('click', function () {
            $("ul.menu-mobile").toggleClass('hidden');
        });

        $(".main-page #video .section-content .video-container .video-block .video-row .video-preview").on("click", function () {
            videoframe_lightbox.show();
            const src = $(this).find('img').attr('src');
            $(".lightbox-container .video-iframe").attr("src", src);
        });

        $(window).scroll(function () {
            if ($(window).width() > 640) {
                if ($(window).scrollTop() < 400) {
                    $(".jp-audio .jp-interface").css({"bottom": "-120px"});
                } else {
                    $(".jp-audio .jp-interface").css({"bottom": "0"});
                }
            } else {
                if ($(window).scrollTop() < 260) {
                    $(".jp-audio .jp-interface").css({"right": "-100px"});
                } else {
                    $(".jp-audio .jp-interface").css({"right": "0"});
                }
            }
            var counter = 0;
            var j;
            for (i = 0; i < JSON[0].listofitems.length; i++) {
                for (j = 0; j < JSON[0].listofitems[i].albumcontent.length; j++) {
                    counter++;
                }
            }
            if (download_flag === 0) {
                var k = 0;
                var offset = 0;
                var album_name = '<p class = "album-name">' + JSON[0].listofitems[k].albumname + '</p>';
                $(".jp-playlist ul li:nth-child(1)").prepend(album_name);
                for (i = 0; i < counter; i++) {
                    if (i - offset === JSON[0].listofitems[k].albumcontent.length) {
                        console.log(k);
                        offset = offset + JSON[0].listofitems[k].albumcontent.length;
                        k++;
                        album_name = '<p class = "album-name secondary">' + JSON[0].listofitems[k].albumname + '</p>';
                        var child_number = offset + 1;
                        $(".jp-playlist ul li:nth-child(" + child_number + ")").prepend(album_name);
                        console.log(offset + k + 1);
                    }
                    var download_button = '<div class = "button-download-container"><a href="../src/mp3/' + String(JSON[0].listofitems[k].albumname) + '/' + String(JSON[0].listofitems[k].albumcontent[i - offset].link) + '" download><div class = "button-download"></div></a></div>';
                    var l = i + 1;
                    var m = ".jp-playlist ul li:nth-child(" + l + ") div";
                    $(m).append(download_button);
                    if (i === counter - 1) {
                        if (download_flag === 1) {
                            download_flag = 2;
                        }
                        download_flag = 1;
                    }
                }
            }
        });

    }
});

function hide_animate() {
    $(".hidden-source").removeClass("hidden-source");
    $(".background-loader").fadeOut("slow");
}

$(window).bind("load", function () {
    hide_animate();

    if ($('.wow').length) {
        initWow();
    }


    function initWow() {
        var wow = new WOW({mobile: true,});
        wow.init();
    }
});

